/*
.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #fff;
}

.ql-toolbar .ql-fill {
  fill: #fff;
  stroke: none;
}

.ql-toolbar .ql-picker {
  color: #fff;
}

.ql-toolbar {
  border-radius: 4px 4px 0 0;
  border: 1px solid rgba(255,255,255,0.23) !important;
}

.ql-container {
  border-radius: 0 0 4px 4px;
  border: 1px solid rgba(255,255,255,0.23) !important;
}
.ql-editor.ql-blank::before {
  color: #c1c1c2 !important;
  font: "Roboto","Helvetica","Arial",sans-serif;
  font-style: normal;
  font-size: 1rem;

}
*/
.ql-editor {
  min-height: 130px;
}